table {
    font-size: 0.9rem;
}

tbody {
    border-top: 0 #DEE2E6 !important;
}

.fs-1rem {
    font-size: 1rem;
}

.form-control:focus, .form-select:focus, .form-check-input:focus, .btn:focus, a:focus {
    box-shadow: none !important;
}

.form-control:focus:not(.is-invalid), .form-select:focus:not(.is-invalid), .form-check-input:focus:not(.is-invalid), .btn-primary, a:focus {
    border-color: #1976D2 !important;
}

.navbar {
    border: 1px solid #DEE2E6;
    background-color: #FFF;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    padding: 0;
}

@media (min-width: 992px) {
    .navbar-nav {
        margin-left: 2rem;
    }
}

.navbar-light .navbar-nav .active {
    border-bottom: 0.25rem solid #1976D2;
    margin-bottom: -0.05rem;
    color: #1976D2 !important;
    font-weight: bold;
    background-color: #edf5fd;
}

@media (max-width: 991px) {
    .navbar-light .navbar-nav .active {
        border-bottom: 0rem;
        color: #1976D2 !important;
        font-weight: bold;
    }
}

.nav-link {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
}

@media (max-width: 991px) {
    .nav-link {
        padding: 0.5rem 1rem !important;
    }
}

.nav-link a
{
    color: rgba(0, 0, 0, 0.55);
    text-decoration: none;
}

.nav-item {
    margin-left: 0.3rem !important;
    margin-right: 0.3rem !important;
}

.nav-icon {
    font-size: 1.5rem;
    margin-right: 0.7rem;
    color: #FF8F00;
}

.nav-title {
    font-size: 2.1rem;
    font-weight:bold;
}

.nav-title span {
    font-size: 1rem;
}

.screen-sub-title {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.1rem;
}

.form-label {
    font-weight: bold;
}

.btn .fa-fw {
    font-size: 1rem;
}

.modal-header {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}

.modal-title {
    font-weight: bold;
}

.notice-box {
    margin-top: 1rem;
}

.toast.alert-success {
    background-color: #2e6930;
    color: #fff;
}

.brand-logo {
    width: 80px;
    height: 40px;
    border: 1px solid #DEE2E6;
    text-align: center;
    padding-top: 8px;
    margin-right: 10px;
}

.icon-tips::before {
    content: "";
    background-color: #087990;
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    -webkit-mask: url("/images/tips.svg");
    mask: url("/images/tips.svg");
    -webkit-mask-size: cover;
    mask-size: cover;
    vertical-align: middle;
    margin-right: 0.5rem;
}

footer {
    margin-top: 3rem;
}
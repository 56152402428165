// コントラスト値（カスタムテーマカラーの文字色を白にするため。注：WCAG 2.0で許容されているRatioは3, 4.5, 7）
$min-contrast-ratio: 2.5;   // 4 → 2.5

// テーマカラー変更
// $primary: #007FB1;
$primary: #1976D2;
$success: #4CAF50;
$warning: #FBC02D;
$danger: #F44336;

// フォント
$font-family-sans-serif: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// ボーダー角丸
$border-radius: 0.125rem !default;
$border-radius-lg: 0.2rem !default;
$border-radius-sm: 0.1rem !default;